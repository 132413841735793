import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="main-container bg--dark" style={{height: '80vh'}}>
      <section className="height-80 bg--dark text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              
              <img
                src={require('../assets/img/koti-icons/404.svg')}
                style={{
                  width: 150,
                  height: 150,
                }}
              />
              <h1 className="h1--large">404</h1>
              <p className="lead">Ooops! You've got lost.</p>
              <Link to="/" className="btn btn--primary type--uppercase">
                {' '}
                <span className="btn__text">Go home</span>{' '}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
